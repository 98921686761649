.tps-comp-thankyou {
    background-color: $ebony-clay-dark;
	border-color: $ebony-clay-dark;
    height: 75vh;

    .close-button {
        background-color: transparent;
        height: rem-calc(23);
        right: rem-calc(15);
        top: rem-calc(12);
        width: rem-calc(23);
		background: url('../img/icon-close.svg') no-repeat 0 0 $white;
		background-size: contain;
		border-radius: 50%;
    }

    &-message {
        align-items: center;
        color: $white;
        display: flex;
        flex-flow: column nowrap;
        height: 100%;
        justify-content: center;

        > p {
            font-size: rem-calc(36);
            font-weight: 600;
            margin-bottom: 0;
            text-align: center;
        }

        em {
            font-weight: normal;
            font-style: italic;
            font-family: $font-family-manulife-serif;
        }

        .icon--envelope {
            background: url('../img/envelope.png') no-repeat 0 0 transparent;
            background-size: contain;
            display: block;
            height: rem-calc(112);
            margin-top: rem-calc(40);
            width: rem-calc(153);
        }
    }
}
