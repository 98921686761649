.tps-footer {
	margin-top: rem-calc(50);
	border-top: rem-calc(1) solid $black;

	&-legal {
		padding: rem-calc(50) 0 rem-calc(70) 0;
		font-size: rem-calc(13);

		@include breakpoint(large) {
			padding: rem-calc(70) 0;
		}

		&-header {
			margin-bottom: rem-calc(20);

			@include breakpoint(large) {
				font-size: rem-calc(14);
			}

			&-big {
				font-size: rem-calc(16);
				font-weight: 600;

				@include breakpoint(large) {
					font-size: rem-calc(24);
				}

			}
		}
	}

	&-bottom {
		padding: 0 rem-calc(20);
		background-color: $dark-navy;
		color: $neutral-dark-gray-3;
		font-size: rem-calc(14);

		@include breakpoint(large) {
			padding: 0 rem-calc(40);
		}

		&-nav {
			padding: rem-calc(20) 0 rem-calc(20-14) 0;

			@include breakpoint(large) {
				padding: rem-calc(20) 0;
			}

			ul {
				display: flex;
				flex-wrap: wrap;
				list-style: none;
				margin: 0 rem-calc(-10);

				@include breakpoint(large) {
					margin: 0 rem-calc(-20);
				}

				li {
					margin: 0 rem-calc(10) rem-calc(14) rem-calc(10);
					width: calc(50% - #{rem-calc(20)});

					@include breakpoint(large) {
						margin: 0 rem-calc(20);
						width: auto;
					}

					a {
						color: $neutral-dark-gray-3;
						line-height: rem-calc(19);
						text-decoration: none;

						&.external {
							&::after {
								content: '';
								display: inline-block;
								margin-left: rem-calc(6);
								width: rem-calc(14);
								height: rem-calc(14);
								background-image: url("data:image/svg+xml,%3Csvg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_2245_285' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='14' height='15'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.4488 3.19303H9.01368V2.02637H12.4763V5.2347H11.3097V3.98086L9.84435 5.51263V12.9744H1.52368V4.65137H9.05373L10.4488 3.19303ZM8.67768 5.81803H2.69035V11.8077H8.67768V5.81803Z' fill='%23221F1F'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_2245_285)'%3E%3Crect y='0.5' width='14' height='14' fill='%23C2C3C9'/%3E%3C/g%3E%3C/svg%3E%0A");							}
						}
					}
				}
			}
		}

		&-copyright {
			border-top: rem-calc(1) solid $neutral-light-navy-1;
			padding: rem-calc(20) 0 rem-calc(40) 0;
			line-height: rem-calc(20);

			@include breakpoint(large) {
				display: flex;
				align-items: center;
			}

			&-logo {
				display: block;
				margin: 0 0 rem-calc(14) 0;

				@include breakpoint(large) {
					margin: 0 rem-calc(14) 0 0;
				}

				img {
					height: rem-calc(20);
					width: auto;
				}
			}
		}
	}


}
