// Loading icon generated by https://loading.io/

@keyframes tps-loading-1 {
	0% { top: rem-calc(2); height: rem-calc(96) }
	50% { top: rem-calc(20); height: rem-calc(60) }
	100% { top: rem-calc(20); height: rem-calc(60) }
}

@keyframes tps-loading-2 {
	0% { top: rem-calc(6.499999999999993); height: rem-calc(87.00000000000001) }
	50% { top: rem-calc(20); height: rem-calc(60) }
	100% { top: rem-calc(20); height: rem-calc(60) }
}

@keyframes tps-loading-3 {
	0% { top: rem-calc(11); height: rem-calc(78) }
	50% { top: rem-calc(20); height: rem-calc(60) }
	100% { top: rem-calc(20); height: rem-calc(60) }
}

.tps-loading {
	background: none;
	display: inline-block;
	height: rem-calc(98);
	opacity: 0.9;
	overflow: hidden;
	width: rem-calc(98);

	&-wrapper {
		align-items: center;
		background-color: rgba($black, 0.5);
		display: flex;
		height: 0;
		justify-content: center;
		left: 0;
		opacity: 0;
		position: fixed;
		top: 0;
		transition: opacity 100ms ease-in-out, height 0ms linear 100ms, width 0ms linear 100ms;
		width: 0;
		z-index: 1007;

		.loading & {
			height: 100%;
			opacity: 1;
			transition: opacity 100ms ease-in-out, height 0ms linear 0ms, width 0ms linear 0ms;
			width: 100%;
		}
	}

	&-inner {
		backface-visibility: hidden;
		height: 100%;
		position: relative;
		transform: translateZ(0) scale(0.98);
		transform-origin: 0 0;
		width: 100%;

		div {
			box-sizing: content-box;
			position: absolute;
			width: rem-calc(15);

			&:nth-child(1) {
				animation: tps-loading-1 1s cubic-bezier(0,0.5,0.5,1) infinite;
				animation-delay: -0.2s;
				background: #ffffff;
				left: rem-calc(17.5);
			}

			&:nth-child(2) {
				animation: tps-loading-2 1s cubic-bezier(0,0.5,0.5,1) infinite;
				animation-delay: -0.1s;
				background: #ffffff;
				left: rem-calc(42.5);
			}

			&:nth-child(3) {
				animation: tps-loading-3 1s cubic-bezier(0,0.5,0.5,1) infinite;
				animation-delay: unset;
				background: #ffffff;
				left: rem-calc(67.5);
			}
		}
	}
}
