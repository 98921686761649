.tps-comp-articles {
	padding: rem-calc(40) 0 rem-calc(36) 0;

	@include breakpoint(large) {
		padding: rem-calc(70) 0;
	}

	&-header {
		margin: 0 0 rem-calc(30) 0;
		font-size: rem-calc(36);
		font-weight: 300;
		line-height: 1.22;

		@include breakpoint(large) {
			font-size: rem-calc(48);
		}
	}

	&-item {
		display: flex;
		flex-direction: column;
		margin-bottom: rem-calc(20);
		border: rem-calc(1) solid $light-navy-5;

		@include breakpoint(tablet) {
			margin-bottom: 0;
			height: 100%;
		}

		&-image {
			width: 100%;
			height: auto;
		}

		&-content {
			padding: rem-calc(30) rem-calc(20);
			line-height: 1.5;
		}

		&-header {
			margin: 0 0 rem-calc(10) 0;
			font-weight: 600;
			font-size: rem-calc(16);
			line-height: inherit;
		}

		&-text {
			margin-bottom: rem-calc(20);
		}

		&-link {
			text-underline-offset: rem-calc(2);
    		text-decoration-thickness: rem-calc(1);
    		text-decoration-color: $green;
		}
	}
}
