.button {
	position: relative;
	min-width: rem-calc(200);
	line-height: rem-calc(24);
	text-decoration: none;

	.icon {
		&::before {
			vertical-align: bottom;
		}
	}

	&.primary, &.secondary {
		color: $white;

		&:hover, &:focus, &.disabled, &.disabled:hover, &.disabled:focus { color: $white; }
    }

	&.secondary {
		background-color: $accent-coral;

		&:hover, &:focus {
			background-color: $accent-coral;
		}
	}

    &.disabled {
        pointer-events: none;
        cursor: default;
    }

	> * {
        pointer-events: none;
    }
}

.link-arrowed {
	display: flex;
	align-items: center;
	text-decoration: none;
	text-align: left;
    line-height: normal;
	min-width: rem-calc(24);
	min-height: rem-calc(24);

	img {
		display: block;
		width: rem-calc(20);
		height: rem-calc(20);
		margin-right: rem-calc(10);
	}

	&.primary {
		color: $body-font-color;

		img {
			content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_1770_1212' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='1' y='1' width='18' height='18'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.99987 17.4832C6.47991 18.4722 8.21997 19 10 19C12.3861 18.9974 14.6738 18.0483 16.361 16.361C18.0483 14.6738 18.9974 12.3861 19 10C19 8.21997 18.4722 6.47991 17.4832 4.99987C16.4943 3.51983 15.0887 2.36628 13.4442 1.68509C11.7996 1.0039 9.99002 0.82567 8.24419 1.17294C6.49836 1.5202 4.89471 2.37737 3.63604 3.63604C2.37737 4.89472 1.5202 6.49836 1.17293 8.24419C0.825666 9.99002 1.00389 11.7996 1.68509 13.4442C2.36627 15.0887 3.51983 16.4943 4.99987 17.4832ZM5.92823 3.90616C7.13347 3.10084 8.55046 2.671 10 2.671C11.943 2.67339 13.8058 3.44631 15.1798 4.82025C16.5537 6.19419 17.3266 8.05696 17.329 10C17.329 11.4495 16.8992 12.8665 16.0938 14.0718C15.2885 15.277 14.1439 16.2164 12.8047 16.7711C11.4655 17.3258 9.99187 17.471 8.57018 17.1882C7.1485 16.9054 5.84259 16.2074 4.81761 15.1824C3.79264 14.1574 3.09462 12.8515 2.81182 11.4298C2.52903 10.0081 2.67417 8.53452 3.22889 7.19532C3.7836 5.85612 4.72298 4.71148 5.92823 3.90616ZM9.286 6.35938L8.097 7.53338L10.621 10.0914L8.046 12.5604L9.203 13.7664L13 10.1244L9.286 6.35938Z' fill='%23282B3E'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_1770_1212)'%3E%3Crect width='20' height='20' transform='matrix(1 0 0 -1 0 20)' fill='%23EC6453'/%3E%3C/g%3E%3C/svg%3E");
		}
	}

	&.white {
		color: $white;

		img {
			content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_1770_1212' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='1' y='1' width='18' height='18'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.99987 17.4832C6.47991 18.4722 8.21997 19 10 19C12.3861 18.9974 14.6738 18.0483 16.361 16.361C18.0483 14.6738 18.9974 12.3861 19 10C19 8.21997 18.4722 6.47991 17.4832 4.99987C16.4943 3.51983 15.0887 2.36628 13.4442 1.68509C11.7996 1.0039 9.99002 0.82567 8.24419 1.17294C6.49836 1.5202 4.89471 2.37737 3.63604 3.63604C2.37737 4.89472 1.5202 6.49836 1.17293 8.24419C0.825666 9.99002 1.00389 11.7996 1.68509 13.4442C2.36627 15.0887 3.51983 16.4943 4.99987 17.4832ZM5.92823 3.90616C7.13347 3.10084 8.55046 2.671 10 2.671C11.943 2.67339 13.8058 3.44631 15.1798 4.82025C16.5537 6.19419 17.3266 8.05696 17.329 10C17.329 11.4495 16.8992 12.8665 16.0938 14.0718C15.2885 15.277 14.1439 16.2164 12.8047 16.7711C11.4655 17.3258 9.99187 17.471 8.57018 17.1882C7.1485 16.9054 5.84259 16.2074 4.81761 15.1824C3.79264 14.1574 3.09462 12.8515 2.81182 11.4298C2.52903 10.0081 2.67417 8.53452 3.22889 7.19532C3.7836 5.85612 4.72298 4.71148 5.92823 3.90616ZM9.286 6.35938L8.097 7.53338L10.621 10.0914L8.046 12.5604L9.203 13.7664L13 10.1244L9.286 6.35938Z' fill='%23282B3E'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_1770_1212)'%3E%3Crect width='20' height='20' transform='matrix(1 0 0 -1 0 20)' fill='%23FFFFFF'/%3E%3C/g%3E%3C/svg%3E");
		}
	}
}


.link-text {
	color: $dark-green-1;
}
