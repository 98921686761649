.tps-comp-tl-banner {
	&-wrapper {
		margin-left: rem-calc(-20);
		margin-right: rem-calc(-20);
		padding: 80% rem-calc(20) rem-calc(20) rem-calc(20);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center top;
		background-image: url('/assets/img/tl-banner-mobile_v2.jpg');
		background-color: $neutral-light-gray;

		@include breakpoint(mobile) {
			padding: 70% rem-calc(20) rem-calc(20) rem-calc(20);
		}

		@include breakpoint(tablet) {
				margin: 0;
				padding: rem-calc(50);
				background-image: url('/assets/img/tl-banner-desktop_v2.jpg');
				background-position: right center;
		}

		&-content {
			@include breakpoint(tablet) {

			}
		}
	}

	&-preheader {
		width: fit-content;
		margin-bottom: rem-calc(25);
		font-weight: 600;
		background-color: $green-salem;
		color: $white;
		padding: rem-calc(1) rem-calc(12);
		text-align: center;
	}

	&-header {
		margin: 0 0 rem-calc(25) 0;
		font-size: rem-calc(30);
		font-weight: 600;
		line-height: 1.22;
		color: $white;

		html[lang="fr-CA"] & {
			font-size: rem-calc(21);
		}

		@include breakpoint(medium) {
			font-size: rem-calc(36);
			
			html[lang="fr-CA"] & {
				font-size: rem-calc(22);
			}
		}

		@include breakpoint(large) {
			font-size: rem-calc(54);

			html[lang="fr-CA"] & {
				font-size: rem-calc(38);
			}
		}

		em {
			font-family: $font-family-manulife-serif;
			font-weight: 400;
			font-style: italic;
		}

		br {
			display: none;

			@include breakpoint(medium) {
				display: block;
			}
		}
	}

	&-text {
		margin-bottom: rem-calc(25);
		font-size: rem-calc(18);
		line-height: 1.33;
		color: $white;

		br {
			display: none;

			@include breakpoint(medium) {
				display: block;
			}
		}
	}

	&-button {
		width: 100%;

		@include breakpoint(tablet) {
			min-width: rem-calc(200);
			width: max-content;
		}
	}

	&-tagline {
		display: block;
		margin-top: rem-calc(50);
		text-align: center;
		font-size: rem-calc(28);

		em {
			font-family: $font-family-manulife-serif;
		}

		sup {
			font-size: rem-calc(8);
    		top: rem-calc(-9);
			left: 0;
    		font-weight: 300;
		}
	}
}
