.tps-comp-quiz {
	margin-left: rem-calc(20);
	margin-right: rem-calc(20);
	background-color: $white;
	border: none;
	border-radius: 0;
	min-height: calc(100% - #{rem-calc(120)});
	height: rem-calc(1);
	padding: rem-calc(93) rem-calc(30) rem-calc(60) rem-calc(30);

	@include breakpoint(medium down) {
		top: rem-calc(85) !important;
	}

	@include breakpoint(tablet) {
		max-width: rem-calc(753) !important;
		min-height: rem-calc(385);
		padding: rem-calc(44) rem-calc(30) rem-calc(33) rem-calc(30);
	}

	&:focus {
		outline: none;
	}

	.close-button {
        background-color: transparent;
		top: rem-calc(10);
		right: rem-calc(10);
        height: rem-calc(30);
        width: rem-calc(30);
		background: url('../img/icon-close.svg') no-repeat 0 0 $white;
		background-size: cover;
    }

	&-slides {
		height: 100%;
		&-item {
			display: flex;
			flex-direction: column;
			height: 100%;

			&-content {
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				width: 100%;

				[data-quiz-slide="0"] &, [data-quiz-slide="result"] & {
					justify-content: center;
				}

				&-header {
					margin-bottom: rem-calc(20);
					text-align: center;
					font-size: rem-calc(36);
					font-weight: 300;
					line-height: 1.22;

					@include breakpoint(tablet) {
						font-size: rem-calc(48);
						line-height: 1.25;
					}
				}

				&-subheader {
					text-align: center;
					font-size: rem-calc(16);
					line-height: 1.5;

					br {
						display: none;

						@include breakpoint(tablet) {
							display: block;
						}
					}
					
				}

				&-question {
					margin-bottom: rem-calc(30);
					font-size: rem-calc(16);
					line-height: 1.5;
					font-weight: 600;

					@include breakpoint(tablet) {
						margin-bottom: rem-calc(37);
					}
				}

				&-result {
					text-align: center;
					font-size: rem-calc(16);
					font-weight: 300;
					line-height: 1.5;

					@include breakpoint(tablet) {
						font-size: rem-calc(30);
					}

					span {
						margin-top: rem-calc(20);
						display: block;
						line-height: 1.25;
						font-size: rem-calc(36);

						@include breakpoint(tablet) {
							font-size: rem-calc(48);
						}
					}
				}

				&-answers {
					&-item {
						&:not(:last-child) {
							margin-bottom: rem-calc(10);
						}

						input {
							position: absolute;
							opacity: 0;
							height: 0;
							width: 0;
						}

						label {
							position: relative;
							margin: 0;
							padding-left: rem-calc(28 + 10);
							font-size: rem-calc(16);
							line-height: rem-calc(28);
							font-weight: 300;

							&::before {
								position: absolute;
								left: 0;
								top: 0;
								content: '';
								width: rem-calc(28);
								height: rem-calc(28);
								background-image: url("../img/checkbox-unchecked.svg");
								background-repeat: no-repeat;
								background-size: cover;
								border-radius: 50%;
							}
						}

						input:checked + label {
							&::before {
								background-image: url("../img/checkbox-checked.svg");
							}
						}

						input:focus-visible + label::before {
							border: rem-calc(3) solid $green;
						}
					}
				}
			}

			&-button {
				margin-bottom: rem-calc(20);

				@include breakpoint(tablet) {
					margin-bottom: 0;
				}

				.tps-comp-quiz-slides-item-content & {
					margin-top: rem-calc(40);
				}
			}

			&-footer {
				display: flex;
				flex-direction: column;
				margin-top: rem-calc(40);

				@include breakpoint(tablet) {
					flex-direction: row;
					justify-content: space-between;
					margin-top: rem-calc(57);
				}
			}

			&:first-child &-footer .button {
				margin-left: auto;
			}
		}
	}
}
