html { font-size: 100%; }

body {
	@include tps-body-margin-top;

	font-size: rem-calc(16);
    overflow-x: hidden;
}

.grid-container.wide { max-width: rem-calc(1316); }

section {
	scroll-margin-top: $header-height-mobile-sticky;

    @include breakpoint(tablet) {
		scroll-margin-top: $header-height-desktop-sticky;
	}
}

sup,
.sup-large {
	position: relative;
	font-size: 60%;
    top: auto;
    vertical-align: super;
    line-height: 0;
	font-weight: 600;
}

.sup-large {
    font-size: 90%;
    top: rem-calc(7);
    font-weight: inherit;
}