// Create breakpoint specific justify styles, missing in foundation by default
@each $breakpoint in $breakpoint-classes {
	@if $breakpoint == small {
        // DO NOTHING
	} @else {
		@include breakpoint($breakpoint) {
            // Horizontal alignments

            // Default
			.#{$breakpoint}-align-left { justify-content: flex-start; }

			.#{$breakpoint}-align-right { justify-content: flex-end; }

			.#{$breakpoint}-align-center { justify-content: center; }

			.#{$breakpoint}-align-justify { justify-content: space-between; }

			.#{$breakpoint}-align-spaced { justify-content: space-around; }

            // Vertical alignments

            // Default
            .#{$breakpoint}-align-stretch { align-items: stretch; }

            .#{$breakpoint}-align-top { align-items: flex-start; }

            .#{$breakpoint}-align-middle { align-items: center; }

            .#{$breakpoint}-align-bottom { align-items: flex-end; }
		}
	}
}

// ###
// Others
// ###

a.tps-skip-nav {
	position: absolute;
	z-index: 9999;
	background-color: $light-navy-2;
	color: $white;
	font-size: rem-calc(14);
	top: 0;
	left: 0;
	padding: rem-calc(3) rem-calc(8) !important;
	margin: 0;

	&:focus {
		width: auto !important;
		height: auto !important;
		overflow: visible !important;
		clip: auto !important;
		white-space: normal !important;
		outline: rem-calc(2) solid $white !important;
	}
}

.no-margin {
    margin: 0 !important;
}
