// FontAwesome icon
@mixin tps-fa-icon() {
    display: inline-block;
    font: normal normal normal 14px/1 'Font Awesome\ 5 Pro';
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin tps-ion-icon() {
	display: inline-block;
	font: normal normal normal 14px/1 'Ionicons';
	text-rendering: auto;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// On window.scroll, the header changes size
// This mixin is applied to key child elements to create the effect as expected
@mixin tps-header-height() {
    height: $header-height-mobile;
    transition: height #{$global-transition};

    @include breakpoint(tablet) { height: $header-height-desktop; }

    body.tps-sticky-header & {
        height: $header-height-mobile-sticky;

        @include breakpoint(tablet) { height: $header-height-desktop-sticky; }
    }
}

// On pages with no masthead, the header should not overlap the body
// This mixin is applied to key child elements to create the effect as expected
@mixin tps-body-margin-top() {
    margin-top: $header-height-mobile;
    transition: margin-top #{$global-transition};

    @include breakpoint(tablet) { margin-top: $header-height-desktop; }

    &.tps-sticky-header {
        margin-top: $header-height-mobile-sticky;

        @include breakpoint(tablet) { margin-top: $header-height-desktop-sticky; }
    }

	&.tps-sticky-header-disabled,
	&.tps-sticky-header-no-spacing {
        margin-top: 0;

        @include breakpoint(tablet) { margin-top: 0; }
    }
}

// Masthead Component height
@mixin tps-masthead-height() {
    height: calc(70vh - #{$header-height-mobile});
    transition: height #{$global-transition};

    @include breakpoint(tablet) { height: calc(100vh - #{$header-height-desktop}); }

    body.tps-sticky-header & {
        height: calc(70vh - #{$header-height-mobile-sticky});

        @include breakpoint(tablet) { height: calc(100vh - #{$header-height-desktop-sticky}); }
	}
}
