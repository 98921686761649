.tps-comp-content {
    padding-top: rem-calc(40);
    padding-bottom: rem-calc(30);
	line-height: 1.5;

	@include breakpoint(large) {
		padding-top: rem-calc(68);
		padding-bottom: rem-calc(20);
	}

	&-text {
		margin-bottom: rem-calc(30);

		@include breakpoint(large) {
			margin-bottom: 0;
			max-width: rem-calc(700);
		}
	}

	&-aside {
		padding-left: 0;

		@include breakpoint(large) {
			padding-left: rem-calc(30);
		}

		&-title {
			margin-bottom: rem-calc(10);
			font-weight: 600;
		}

		ul {
			margin: 0;
			list-style: none;

			li {
				position: relative;
				margin-bottom: rem-calc(3);
				padding-left: rem-calc(15 + 12);

				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: 0.4em;
					display: inline-block;
					background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 4.66105L6.81169 9.37689L14.4449 1.62305' stroke='%2300A758' stroke-width='3'/%3E%3C/svg%3E");
					background-size: cover;
					background-position: center;
					width: rem-calc(15);
					height: rem-calc(11);
				}
			}
		}
	}

	&-outro {
		margin-top: rem-calc(50);
		font-size: rem-calc(36);
		font-weight: 300;
		line-height: 1.2222;

		@include breakpoint(large) {
			margin-top: rem-calc(60);
			font-size: rem-calc(48);
			line-height: 1.25;
		}

		&-small {
			margin-top: rem-calc(21.5);
			font-size: rem-calc(30);
			font-weight: 600;
			line-height: 1.2;

			@include breakpoint(large) {
				margin-top: rem-calc(20);
				font-size: rem-calc(32);
				line-height: 1.375;
			}
		}
	}

	p, ul, li {
		&:last-child {
			margin-bottom: 0;
		}
	}
}
