.tps-comp-faqmodal {
	padding: rem-calc(20);
	background-color: $ebony-clay-dark;
	border-radius: none;
	border: none;
	color: $white;
	overflow: hidden;

	@include breakpoint(large) {
		padding: rem-calc(50) rem-calc(20) rem-calc(80) rem-calc(60);
	}

	.close-button {
        background-color: transparent;
		top: rem-calc(10);
		right: rem-calc(10);
        height: rem-calc(30);
        width: rem-calc(30);
		background: url('../img/icon-close-white.svg') no-repeat 0 0;
		background-size: cover;
    }

	&-header {
		margin-bottom: rem-calc(20);
		font-size: rem-calc(30);
		font-weight: 600;

		@include breakpoint(large) {
			font-size: rem-calc(46);
		}
	}

	&-content {
		max-height: 80vh;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: rem-calc(20);
		}

		&::-webkit-scrollbar-track {
			box-shadow: none;
			outline: rem-calc(1) solid $iron;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $trout;
		}
	}

	.accordion {
		background: transparent;
		counter-reset: accordion;

		&-item {
			&.is-active {
				> .accordion-title::after {
					content: '';
					background-image: url('../img/accordion-open.svg');
				}
			}
		}

		&-title {
			padding: rem-calc(10) rem-calc(50) rem-calc(10) rem-calc(34);
			border: none !important;
			color: $white;
			font-size: rem-calc(20);
			font-weight: 600;
			line-height: 1.48;
			text-decoration: none;

			@include breakpoint(large) {
				padding-right: rem-calc(170);
				font-size: rem-calc(33);
			}

			&::before {
				counter-increment: accordion;
				content: counter(accordion)".";
				position: absolute;
				left: 0;
				right: auto;
				top: auto;
				margin: 0;
			}

			&::after {
				content: '';
				position: absolute;
				display: block;
				top: rem-calc(10);
				right: rem-calc(20);
				margin: 0;
				width: rem-calc(20);
				height: rem-calc(20);
				background-image: url('../img/accordion-closed.svg');
				background-size: rem-calc(20);
				background-repeat: no-repeat;

				@include breakpoint(large) {
					right: rem-calc(70);
					width: rem-calc(42);
					height: rem-calc(42);
					background-size: rem-calc(42);
				}
			}
		}

		&-content {
			padding: 0 rem-calc(50) rem-calc(30) rem-calc(34);
			background: transparent;
			border: none;
			color: $white;
			font-size: rem-calc(18);
			line-height: 1.5;

			@include breakpoint(large) {
				padding-right: rem-calc(170);
				font-size: rem-calc(25);
			}

			> *:last-child {
				margin-bottom: 0;
			}
		}
	}
}
