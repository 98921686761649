// Scroll down button bounce
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {
		transform: translateX(-50%) translateY(0);
	}

	40% {
		transform: translateX(-50%) translateY(#{rem-calc(-20)});
	}

	60% {
		transform: translateX(-50%) translateY(#{rem-calc(-10)});
	}
}
