.tps-comp-slider {
	position: relative;
	background-color: $neutral-light-gray;

	&-slides {
		position: relative;

		&-item {
			position: relative;
			height: 100%;

			> .grid-container {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				height: 100%;

				@include breakpoint(tablet) {
					flex-direction: row;
					align-items: center;
					justify-content: normal;
				}
			}

			&-content {
				padding: rem-calc(30) 0;

				br {
					display: none;
				}

				@include breakpoint(tablet) {
					padding: rem-calc(60) rem-calc(20) rem-calc(60) 0;
					width: 50%;

					br {
						display: block;
					}
				}

				@include breakpoint(large) {
					padding: rem-calc(90) rem-calc(35) rem-calc(90) 0;
				}
			}

			&-image {
				margin: 0 rem-calc(-20);
				padding-bottom: 66.66%; // 390x260 proportions
				background-size: cover;
				background-position: center center;

				@include breakpoint(tablet) {
					position: absolute;
					left: 50%;
					right: 0;
					top: 0;
					width: 50%;
					height: 100%;
					margin: 0;
					padding-bottom: 0;
				}
			}

			&-title {
				margin-bottom: rem-calc(30);
				font-size: rem-calc(36);
				line-height: 1.22;
				font-weight: 600;

				@include breakpoint(large) {
					margin-bottom: rem-calc(45);
					font-size: rem-calc(56);
				}
				
				.serif {
					font-family: $font-family-manulife-serif;
					font-weight: 400;

					// @include breakpoint(large) {
					// 	font-size: rem-calc(60);
					// }
				}

				&.smaller {
					@include breakpoint(large) {
						font-size: rem-calc(42);
					}
				}

				.bigger {
					@include breakpoint(large) {
						font-size: rem-calc(60);
					}
				}
			}

			&-subtitle {
				font-size: rem-calc(18);
				line-height: rem-calc(24);
				margin: rem-calc(-10) 0 rem-calc(35) 0px;
				
				@include breakpoint(tablet) {
					font-size: rem-calc(22);
					line-height: rem-calc(28);
				}

				@include breakpoint(large) {
					font-size: rem-calc(36);
					margin: rem-calc(-20) 0 rem-calc(35) 0px;
					line-height: rem-calc(44);
				}

				&.smaller {
					@include breakpoint(large) {
						font-size: rem-calc(28);
						line-height: rem-calc(36);
					}
				}
			}

			&-button {
				width: 100%;
				max-width: rem-calc(350);

				@include breakpoint(tablet) {
					width: auto;
				}
			}
		}
	}
}

.slick-track {
	align-items: stretch;
	.slick-slide {
		height: auto;

		> div {
			height: 100%;
		}
	}
}

.slick-dots {
	position: absolute;
	bottom: rem-calc(18);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	list-style: none;
	margin: 0;
	padding: 0 rem-calc(15);

	@include breakpoint(large) {
		bottom: rem-calc(24);
		margin: 0;
	}

	& > li {
		line-height: 1;

		& + li {
			margin-left: rem-calc(44);

			@include breakpoint(large) {
				margin-left: rem-calc(36)
			}
		}

		& > button {
			display: block;
			overflow: hidden;
			border-radius: 50%;
			width: rem-calc(10);
			height: rem-calc(10);
			background: transparent;
			border: rem-calc(2) solid $white;
			line-height: 1;
			text-indent: -99999px;
			font-size: 0;
			cursor: pointer;

			@include breakpoint(tablet) {
				border-color: $ebony-clay-dark;
			}
		}

		&.slick-active > button {
			width: rem-calc(13);
			height: rem-calc(13);
			background: $white;

			@include breakpoint(tablet) {
				background: $ebony-clay-dark;
				border-color: $ebony-clay-dark;
			}
		}
	}
}

.slick-arrow {
	position: absolute;
	width: rem-calc(48);
	height: rem-calc(48);
	cursor: pointer;

	&.slick-prev {
		display: none !important;
	}

	&.slick-next {
		bottom: calc(66.66vw / 2 - #{rem-calc(45)}); // approx calculation based on the bottom image prop
		right: 0;

		@include breakpoint(tablet) {
			top: 50%;
			bottom: auto;
			transform: translateY(-50%);
		}

		@include breakpoint(large) {
			right: rem-calc(57);
		}

		[data-arrow="light"] & {
			svg path {
				fill: $white;
			}
		}
	}
}
