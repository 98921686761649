.tps-header {

	// Header nav

	&-nav {
		background-color: $gun-powder;
		position: fixed;
		top: 0;
		transition: box-shadow #{$global-transition};
		width: 100%;
		z-index: 10;

		body.tps-sticky-header-disabled & {
			position: static;
			top: auto;
		}

		body.tps-sticky-header & { box-shadow: $black-shadow; }

		.grid-container > .grid-x:first-of-type {
			@include tps-header-height;
			justify-content: space-between;

			@include breakpoint(large) {
				padding-right: rem-calc(25);
			}
		}

		// Logo

		&-logo {
			display: block;

			img {
				width: auto;
				height: rem-calc(31);
			}
		}

		// Link

		&-link,
		.dropdown.menu > li > a {
    		align-items: center;
			color: $white;
			display: flex;
			font-size: rem-calc(19);
			font-weight: 600;
			line-height: rem-calc(25);
			padding: rem-calc(5) rem-calc(14);
			text-decoration: none;

			&:hover,
			&:focus { color: $white; }

			&:not(.dropdown-icon) {
				width: 100%;
			}

			&.disabled {
				pointer-events: none;
			}

			&-text {
				font-size: rem-calc(16);
				border-bottom: rem-calc(2) solid transparent;
				border-top: rem-calc(2) solid transparent;
				display: block;
				transition: border-bottom-color #{$global-transition};

				.tps-header-nav-link:hover:not(.dropdown-icon) &,
				.tps-header-nav-link:focus &,
				.tps-header-nav-link.scroll-to-calculator {
					border-bottom-color: $white;
				}

				@include breakpoint(xlarge) {
					font-size: rem-calc(19);
				}
			}

			@include breakpoint(xlarge) {
				margin-bottom: 0;
				width: auto;
			}
			
			@include breakpoint(xxlarge) {
				margin-bottom: 0;
				padding: 0 rem-calc(20);
			}
		}

		.dropdown.menu {
			li {
    			flex-direction: column;
				justify-content: flex-start;
				
				@include breakpoint(xlarge) {
					flex-direction: row;
					justify-content: flex-end;
				}
			}
		}
		
		&-container {
			position: absolute;
			flex-direction: column;
			list-style: none;
			background-color: $gun-powder;
			top: rem-calc(55);
			right: rem-calc(-9999);
			max-width: rem-calc(310) !important;
			min-width: rem-calc(280) !important;
			margin: 0;
    		padding: rem-calc(25) rem-calc(10) rem-calc(10) rem-calc(5) !important;
			transition: all .4s ease-in-out;

			&.active {
				right: 0;
			}

			@include breakpoint(xlarge) {
				
			}

			@include breakpoint(xlarge) {
				position: initial;
				flex-direction: row;
				background-color: $gun-powder;
				top: auto;
				left: auto;
				width: auto;
				max-width: none !important;
				padding: 0 !important;

				li ~ li {
					border-left: rem-calc(2) solid $white;
				}
			}

			.dropdown-icon {
				padding-right: rem-calc(45) !important;
			}
		}

		.dropdown.menu {
			& > li.is-dropdown-submenu-parent {			
				& > a::after {
					content: '';
					border: 0;
					height: rem-calc(25);
					width: rem-calc(25);
					right: rem-calc(150);
					margin-top: rem-calc(-13);
					background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_1770_1002' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='7' y='9' width='10' height='8'%3E%3Cpath d='M12 16.08L7.20001 11.4L9.00001 9.59998L12 12.72L15 9.59998L16.8 11.4L12 16.08Z' fill='%23FFFFFF'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_1770_1002)'%3E%3Crect width='24' height='24' fill='%23FFFFFF'/%3E%3C/g%3E%3C/svg%3E");
					background-size: rem-calc(24) rem-calc(24);

					@include breakpoint(xlarge) {
						right: rem-calc(15);
					}
				}
			}

			li.is-dropdown-submenu-parent.is-active {
				a::after {
					transform: rotate(180deg);
					
					@include breakpoint(xlarge) {
						right: rem-calc(15);
					}
				}
			}

			ul.is-dropdown-submenu {
				position: relative;
				border: 0;
				background-color: $gun-powder;
				top: 0 !important;
				right: auto !important;
    			left: 0 !important;
				margin: rem-calc(5) 0;

				@include breakpoint(xlarge) {
					position: absolute;
					top: rem-calc(57) !important;
					margin: 0;
				}

				@include breakpoint(xxlarge) {
					top: rem-calc(52) !important;
				}

				.tps-sticky-header & {
					top: rem-calc(47) !important;

					@include breakpoint(xxlarge) {
						top: rem-calc(42) !important;
					}
				}

				li {
					border: 0;

					.tps-header-nav-link {
						width: 100%;
						border-bottom: 0;
						margin-left: rem-calc(10);

						&:hover,
						&:focus {
							@include breakpoint(xlarge) {
								background-color: $comet;
							}
						}

						@include breakpoint(xlarge) {
							border-bottom: rem-calc(1) solid $neutral-dark-navy-4;
							margin: 0;
						}
					}

					.tps-header-nav-link-text {
						width: max-content;
						font-size: rem-calc(16);
						font-weight: 400;
					}
				}
			}
		}

		.dropdown-submenu {
			display: none;
			width: max-content;
			background-color: $gun-powder;
			top: rem-calc(55);
			left: rem-calc(-10);

			@include breakpoint(xlarge) {
				position: absolute;
				top: rem-calc(57) !important;
			}

			@include breakpoint(xxlarge) {
				position: absolute;
				top: rem-calc(52) !important;
			}

			&.active {
				display: block;
			}

			.tps-sticky-header & {
				top: rem-calc(47) !important;

				@include breakpoint(xxlarge) {
					top: rem-calc(42) !important;
				}
			}

			li {
				border: 0;

				&:last-of-type {
					.tps-header-nav-link {
						border-bottom: 0;
					}
				}

				.tps-header-nav-link {
					width: 100%;
					border-bottom: 0;
					margin-left: rem-calc(10);

					&:hover,
					&:focus {
						@include breakpoint(xlarge) {
							background-color: $comet;
						}
					}

					&-text {
						width: max-content;
						max-width: rem-calc(200);
						line-height: rem-calc(19);

						@include breakpoint(xlarge) {
							max-width: none;
							line-height: rem-calc(22);
						}
					}

					@include breakpoint(xlarge) {
						border-bottom: rem-calc(1) solid $neutral-dark-navy-4;
						margin: 0;
					}
				}
			}

		}
	}

	&-menu-toggle {
		display: inline-block;
		padding: rem-calc(5) rem-calc(20) 0 rem-calc(4);
		cursor: pointer;
	}
}
