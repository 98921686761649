.tps-comp-apply {
	padding: rem-calc(23) 0;
	background: $neutral-light-gray;

	@include breakpoint(large) {
		padding: rem-calc(53) 0;
	}

	&-inner {
		padding: rem-calc(50) rem-calc(30);
		background-color: $white;

		@include breakpoint(large) {
			padding: rem-calc(50);
		}
	}

	&-title {
		margin: 0 0 rem-calc(20) 0;
		line-height: 1.2222;
		font-weight: 300;
		font-size: rem-calc(36);

		@include breakpoint(large) {
			font-size: rem-calc(48);
		}
	}

	&-subtitle {
		margin-bottom: rem-calc(40);
		font-size: rem-calc(24);
		font-weight: 600;
		line-height: 1.2;

		@include breakpoint(medium) {
			margin: 0;
			font-size: rem-calc(29);
		}

		@include breakpoint(large) {
			margin: 0;
			font-size: rem-calc(32);
		}
	}

	&-formwrap {
		@include breakpoint(large) {
			display: flex;
		}
	}

	&-select {
		margin: 0 0 rem-calc(10) 0;

		@include breakpoint(large) {
			margin: 0 rem-calc(10) 0 0;
			width: rem-calc(335);
		}
	}

	&-applybutton {
		margin: 0 0 rem-calc(30) 0;
		width: 100%;

		@include breakpoint(large) {
			margin: 0;
			min-width: rem-calc(200);
			width: max-content;
		}
	}

	&-link {
		margin-top: rem-calc(10);

		@include breakpoint(large) {
			margin-top: rem-calc(40);
		}
	}

	&-details {
		margin-top: rem-calc(50);

		&-title{
			font-size: rem-calc(32);
			font-weight: lighter;
		}

		.accordion {
			&-item {
				margin-top: rem-calc(20);
				border: rem-calc(1) solid $neutral-dark-navy-4;
				background-color: $white;
			}

			&-title {
				padding: rem-calc(20) rem-calc(20) rem-calc(20) rem-calc(51);
				font-size: rem-calc(18);
				font-weight: 600;
				color: $neutral-light-navy-1;
				text-decoration: none;
				overflow: auto;

				&::before {
					top: rem-calc(28);
    				left: rem-calc(18);
					right: auto;
					width: rem-calc(18);
					height: rem-calc(18);
					border-radius: 50%;
					background-color: $accent-coral;
					color: $white;
					padding-left: rem-calc(4);
    				line-height: rem-calc(12);
				}

				&[aria-selected='true'] {
					&::before {
						padding-left: rem-calc(3);
    					line-height: rem-calc(10);
						background-color: $white;
						border: rem-calc(2) solid $accent-coral;
						color: $accent-coral;
					}
				}
			}

			&-content {
				padding: rem-calc(5) rem-calc(15) rem-calc(15) rem-calc(55);
				font-weight: 300;

				ul, ol {
					margin-left: rem-calc(30);
				}
			}
		}
	}
}
