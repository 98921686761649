$jade: #00ac5b;
$mine-shaft: #373737;
$kaitoke-green: #004427;
$anakiwa: #92E3FB;
$shuttle-gray: #5E6271;
$mischka: #E3E3E6;
$gun-powder: #434658;
$dark-blue: #0000C1;
$black-pearl: #081A31;
$iron: #DBDDE0;
$ebony-clay: #272B3D;
$ebony-clay-dark: #282b3e;
$trout: #4C4C5F;
$green-salem: #06874E;
$masthead-white: #fefcfc;
$slick-dot-gray: #525564;
$tooltip-button-bg: #3d4054;
$neutral-light-gray: #ededed;
$accent-coral: #EC6453;
$neutral-light-navy-1: #34384B;
$neutral-dark-navy-4: #8E90A2;
$dark-navy: #282b3e;
$dark-green-1: #06874E;
$dark-green-2: #046138;
$light-navy-5: #DDDEE4;
$light-navy-2: #424559;
$green: #00A758;
$light-gray: #fafafa;
$neutral-dark-gray-2: #DFE0E2;
$neutral-dark-gray-3: #C2C3C9;
$comet: #5E6073;

// B&W
$black: #000000;
$white: #FFFFFF;

// Foundation
$foundation-palette: (
    primary: $accent-coral,
    secondary: $dark-blue,
    success: #3adb76,
    warning: #ffae00,
    alert: #cc4b37,
);
$medium-gray: #cacaca;
$dark-gray: #828181;
$black: #000000;
$white: #FFFFFF;
