.tps-comp-comparemodal {
	margin-left: rem-calc(20);
	margin-right: rem-calc(20);
    padding: rem-calc(64) 0 rem-calc(30) 0;
	border: none;
	border-radius: 0;
	background: $white;
	overflow: hidden;
	height: 85vh;
	width: calc(100% - #{rem-calc(40)}) !important;

	@include breakpoint(large) {
		padding: rem-calc(64) rem-calc(30) rem-calc(30) rem-calc(30);

		&.quiz-result {
			max-width: $ct-width-first-column-xxlarge + $ct-width-plan-column-xxlarge * 2 + $ct-column-gap * 2 + rem-calc(30 * 2 + 14) !important;
		}

		&.only-base, &.only-bronze, &.only-silver, &.only-gold {
			max-width: $ct-width-first-column-xxlarge + $ct-width-plan-column-xxlarge + $ct-column-gap + rem-calc(30 * 2 + 14) !important;
		}
	}

	@include breakpoint(xxlarge) {
		padding: rem-calc(35) rem-calc(77) rem-calc(74) rem-calc(90);
		max-width: rem-calc(1230) !important;

		&.quiz-result {
			max-width: $ct-width-first-column-xxlarge + $ct-width-plan-column-xxlarge * 2 + $ct-column-gap * 2 + rem-calc(77 + 90 + 14) !important;
		}

		&.only-base, &.only-bronze, &.only-silver, &.only-gold {
			max-width: $ct-width-first-column-xxlarge + $ct-width-plan-column-xxlarge + $ct-column-gap + rem-calc(77 + 90 + 14) !important;
		}
	}

	&:focus {
		outline: none;
	}

    .close-button {
        background-color: transparent;
		top: rem-calc(10);
		right: rem-calc(10);
        height: rem-calc(30);
        width: rem-calc(30);
		background: url('../img/icon-close.svg') no-repeat 0 0 $white;
		background-size: cover;
    }

	&-header {

		&-arrow {
			position: absolute;
			z-index: 1;
			display: block;
			top: rem-calc(10);
			width: rem-calc(24);
			height: rem-calc(24);
			background-size: cover;
			background-color: transparent;
			cursor: pointer;

			@include breakpoint(large) {
				display: none;
			}

			&[data-dir="left"] {
				left: $ct-width-first-column-small - rem-calc(17);
				background-image: url('/assets/img/table-left.svg');
			}

			&[data-dir="right"] {
				left: calc(#{$ct-width-first-column-small} + 100vw - #{$ct-width-first-column-small + $ct-column-gap} - #{$ct-width-plan-column-offset-portion} + #{$ct-column-gap} - 8px);
				background-image: url('/assets/img/table-right.svg');
			}
		}
	}

    &-contentbox {
		overflow-y: auto;
		overflow-x: hidden;
        width: 100%;
		height: calc(100% - #{rem-calc(56)}); // minus height of table "header" with spacing
		padding-right: rem-calc(7);

		&::-webkit-scrollbar {
			width: rem-calc(7);
			height: rem-calc(7);
			border-radius: rem-calc(4);
			background: $neutral-dark-gray-2;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: rem-calc(4);
			background: $neutral-dark-navy-4;
		}
    }
}
