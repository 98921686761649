.plans-table {
	position: relative;
    margin-bottom: 0;

	@include breakpoint(large) {
		.quiz-result & {
			width: $ct-width-first-column-xxlarge + $ct-width-plan-column-xxlarge * 2 + $ct-column-gap * 2;
		}

		.only-base &, .only-bronze &, .only-silver &, .only-gold & {
			width: $ct-width-first-column-xxlarge + $ct-width-plan-column-xxlarge + $ct-column-gap;
		}
	}

	@include breakpoint(xxlarge) {
		max-width: rem-calc(1048);
	}

	&, tbody, thead {
		display: block;
	}

	tr {
		display: flex;
		background: transparent;
		width: calc(#{$ct-width-first-column-small} + (100vw - #{$ct-width-first-column-small + $ct-column-gap * 2} - #{$ct-width-plan-column-offset-portion} + #{$ct-column-gap})*4 + 20vw);

		@include breakpoint(large) {
			width: 100%;
		}

		th, td {
			margin: $ct-column-gap 0 0 $ct-column-gap;
			padding: rem-calc(10) rem-calc(20);
			flex: 0 0 auto;

			&[colspan="5"] {
				width: calc(100vw - var(--scrollbar-width) - #{rem-calc(40)});
				@include breakpoint(large) {
					width: 100%;
				}
			}

			&:first-child {
				margin-left: 0;
				position: sticky;
				left: 0;
				z-index: 2;

				@include breakpoint(large) {
					position: static;
				}

				&:not([colspan="5"]) {
					width: $ct-width-first-column-small;

					@include breakpoint(large) {
						width: $ct-width-first-column-large;

						.quiz-result &, .only-base &, .only-bronze &, .only-silver &, .only-gold & {
							width: $ct-width-first-column-xxlarge;
						}
					}

					@include breakpoint(xxlarge) {
						width: $ct-width-first-column-xxlarge;
					}
				}
			}

			&:not(:first-child) {
				width: calc(100vw - #{$ct-width-first-column-small + $ct-column-gap * 2} - #{$ct-width-plan-column-offset-portion}); // second column's small part is visible

				@include breakpoint(large) {
					width: calc((100% - #{$ct-width-first-column-large}) / 4 - #{$ct-column-gap}); // equal four minus the first column

					.quiz-result &, .only-base &, .only-bronze &, .only-silver &, .only-gold & {
						width: $ct-width-plan-column-xxlarge;
					}
				}

				@include breakpoint(xxlarge) {
					width: $ct-width-plan-column-xxlarge;
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}

		.only-base & {
			td:nth-child(3), td:nth-child(4), td:nth-child(5),
			th:nth-child(3), th:nth-child(4), th:nth-child(5) {
				display: none;
			}
		}

		.only-bronze & {
			td:nth-child(2), td:nth-child(4), td:nth-child(5),
			th:nth-child(2), th:nth-child(4), th:nth-child(5) {
				display: none;
			}
		}

		.only-silver & {
			td:nth-child(2), td:nth-child(3), td:nth-child(5),
			th:nth-child(2), th:nth-child(3), th:nth-child(5) {
				display: none;
			}
		}

		.only-gold & {
			td:nth-child(2), td:nth-child(3), td:nth-child(4),
			th:nth-child(2), th:nth-child(3), th:nth-child(4) {
				display: none;
			}
		}

		.base-bronze & {
			td:nth-child(4), td:nth-child(5),
			th:nth-child(4), th:nth-child(5) {
				display: none;
			}

			td:nth-child(2) {
				background-color: $neutral-light-gray !important;
			}

			&.rates td:nth-child(2) {
				background-color: $dark-green-1 !important;
			}
		}

		.bronze-base & {
			td:nth-child(4), td:nth-child(5),
			th:nth-child(4), th:nth-child(5) {
				display: none;
			}

			td:nth-child(2), th:nth-child(2) {
				order: 1;
			}

			td:nth-child(3) {
				background-color: $neutral-light-gray !important;
			}

			&.rates td:nth-child(3) {
				background-color: $dark-green-1 !important;
			}
		}

		.silver-bronze & {
			td:nth-child(2), td:nth-child(5),
			th:nth-child(2), th:nth-child(5) {
				display: none;
			}

			td:nth-child(3), th:nth-child(3) {
				order: 1;
			}

			td:nth-child(4) {
				background-color: $neutral-light-gray !important;
			}

			&.rates td:nth-child(4) {
				background-color: $dark-green-1 !important;
			}
		}

		.gold-silver & {
			td:nth-child(2), td:nth-child(3),
			th:nth-child(2), th:nth-child(3) {
				display: none;
			}

			td:nth-child(4), th:nth-child(4) {
				order: 1;
			}

			td:nth-child(5) {
				background-color: $neutral-light-gray !important;
			}
			&.rates td:nth-child(5) {
				background-color: $dark-green-1 !important;
			}
		}
	}

	thead {
		position: sticky;
		top: 0;
        z-index: 3;
		background: $white;

		tr {
			background: $white;
		}

		th {
			margin-top: 0;
			margin-bottom: rem-calc(12);

			&:first-child {
				/* Underlayers to hide the partially scrolled in second plan */
				&::before, &::after {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					height: 100%;
					background-color: $white;

					@include breakpoint(large) {
						display: none;
					}
				}

				&::before {
					left: 0;
					width: $ct-width-first-column-small;
				}

				&::after {
					left: calc(#{$ct-width-first-column-small} + 100vw - #{$ct-width-first-column-small + $ct-column-gap} - #{$ct-width-plan-column-offset-portion} + #{$ct-column-gap});
					width: 20vw;
				}
			}

			&:not(:first-child) {
				background-color: $green;
				color: $white;
				font-weight: 600;
			}
		}
	}

    tbody {
		tr:first-child th {
			margin-top: 0;
		}

        th {
			margin-top: rem-calc(20);
            background-color: $ebony-clay-dark;
            color: $white;
			font-size: rem-calc(14);
			line-height: rem-calc(20);
			font-weight: 400;
            text-align: left;

			@include breakpoint(large) {
				margin-top: rem-calc(30);
			}
        }

        td {
			background-color: $light-gray;
            font-size: rem-calc(14);
			line-height: rem-calc(20);
            vertical-align: top;

			&.active {
				background-color: $neutral-light-gray !important;
			}

			&.section-description {
				background-color: transparent;
				padding: rem-calc(10) rem-calc(20);

				@include breakpoint(large) {
					padding: rem-calc(15) rem-calc(20) rem-calc(20) rem-calc(20);
				}
			}
        }

		tr.rates {
			margin-top: rem-calc(20);

			.no-quotes & {
				display: none;
			}

			td:first-child {
				padding: 0 rem-calc(20);
				background: $white;

				@include breakpoint(large) {
					display: flex;
					align-items: center;
					justify-content: end;
				}
			}

			td:not(:first-child) {
				background: $green;
				color: $white;
				font-size: rem-calc(15);
				line-height: rem-calc(24);
				font-weight: 600;
				padding: rem-calc(10) rem-calc(10);
				
				@include breakpoint(medium) {
					font-size: rem-calc(16);
					padding: rem-calc(10) rem-calc(20);
				}

				&.active {
					background: $green !important;
				}
			}
		}
    }

	&-forms {
		margin-top: rem-calc(32);
		padding: rem-calc(60) rem-calc(40);
		background: $light-gray;

		.no-quotes & {
			display: none !important;
		}

		@include breakpoint(large) {
			.tps-comp-comparemodal:not(.only-base):not(.only-bronze):not(.only-silver):not(.only-gold) & {
				margin-top: rem-calc(40);
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-direction: row-reverse;
			}
		}
	}

	&-apply {
		@include breakpoint(large) {
			.tps-comp-comparemodal:not(.only-base):not(.only-bronze):not(.only-silver):not(.only-gold) & {
				width: 60%;
				max-width: rem-calc(340);
			}
		}

		&-title {
			margin-bottom: rem-calc(10);
			font-size: rem-calc(36);
			line-height: rem-calc(44);

			@include breakpoint(large) {
				font-size: rem-calc(48);
				line-height: rem-calc(60);
			}
		}

		&-subtitle {
			margin-bottom: rem-calc(30);
			font-size: rem-calc(16);
			line-height: rem-calc(24);
		}

		&-form {

			&-select {
				margin-bottom: rem-calc(10);
			}
			&-applybutton {
				width: 100%;
			}
		}
	}

	&-savequote {
		margin-top: rem-calc(70);

		@include breakpoint(large) {
			.tps-comp-comparemodal:not(.only-base):not(.only-bronze):not(.only-silver):not(.only-gold) & {
				margin-top: 0;
				width: 40%;
				max-width: rem-calc(245+10+106);
			}
		}

		&-title {
			margin-bottom: rem-calc(10);
			font-size: rem-calc(26);
			font-weight: 600;
			line-height: rem-calc(36);
		}

		&-subtitle {
			margin-bottom: rem-calc(25);
			font-size: rem-calc(16);
			line-height: rem-calc(24);
		}

		&-form {
			@include breakpoint(large) {
				display: flex;
			}

			&-input {
				margin: 0 0 rem-calc(10) 0;
				@include breakpoint(large) {
					margin: 0;
					width: rem-calc(245);
				}
			}

			&-button {
				width: 100%;
				@include breakpoint(large) {
					margin-left: rem-calc(10);
					width: rem-calc(106);
					min-width: initial;
				}
			}
		}
	}

	&-footer {
		padding: rem-calc(30) rem-calc(28) rem-calc(55) rem-calc(28);
		font-size: rem-calc(13);
		line-height: rem-calc(16); /* 123.077% */

		> *:last-child {
			margin-bottom: 0;
		}
	}

	&-forms, &-footer {
		position: sticky;
		left: 0;
		z-index: 1;

		@include breakpoint(large) {
			position: static;
		}
	}
}
